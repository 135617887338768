html,body, #page, header { height:100%; }

#page { position:relative;  }
#content { padding-bottom:130px;} /* FOOTER HEIGHT */
footer { position:absolute; bottom:0; width: 100% }

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}

/* Overwrite antd defaults */

.ant-checkbox-group-item {
  display: block;
  margin-right: 0;
}

.ant-select .ant-select-selection .ant-select-selector {
  border-radius: '5px';
}

.ant-thin-toolbar .ant-tooltip-inner {
  padding: 2px 2px;
  word-wrap: break-word;
}

.ant-space-vertical {
  width: 100%;
}

.fadeOut{
  opacity:1;
  width:0;
  height:0;
  transition: width 1s 1s, height 1s 1s, opacity 1s;
}
#slide {
  position: absolute;
  left: -1000px;
  background: blue;
  transition: 1s;
}
.fadeIn #slide {
  transition: 1s;
  left: 0;
}

/*
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
*/

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
